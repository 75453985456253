<template>
  <div class="tw-py-6 lg:tw-py-8">
    <div class="tw-container tw-px-6 tw-mx-auto tw-flex tw-flex-col md:tw-flex-row tw-items-start md:tw-items-center tw-justify-between">
      <div>
        <p class="tw-flex tw-items-center tw-text-indigo-700 tw-text-xs">
          <span>Portal</span>
          <span class="tw-mx-2">&gt;</span>
          <span>Dashboard</span>
          <span class="tw-mx-2">&gt;</span>
          <span>KPIs</span>
        </p>
        <h4 class="tw-text-2xl tw-font-bold tw-leading-tight tw-text-gray-800 dark:tw-text-gray-100">
          Dashboard
        </h4>
      </div>
      <div class="tw-mt-6 md:tw-mt-0">
        <button class="tw-mr-3 tw-bg-gray-200 dark:tw-bg-gray-700 focus:tw-outline-none tw-transition tw-duration-150 tw-ease-in-out tw-rounded hover:tw-bg-gray-300 tw-text-indigo-700 dark:hover:tw-bg-gray-600 dark:tw-text-indigo-600 tw-px-5 tw-py-2 tw-text-sm">
          Back
        </button>
        <button class="tw-transition focus:tw-outline-none tw-duration-150 tw-ease-in-out hover:tw-bg-indigo-600 tw-bg-indigo-700 tw-rounded tw-text-white tw-px-8 tw-py-2 tw-text-sm">
          Edit Profile
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name: 'SimpleWithBreadcrumbs',
}
</script>
